<template>
  <div class="update-wrap ly-wrap">
    <div class="currency-title"><span class="back" @click="goBack"></span>检查更新</div>
    <div class="update-box">
      <h1>智慧水务</h1>
      <p>1.2</p>
    </div>
  </div>
</template>
<script>

export default {
  components: {},
  computed: {},
  data() {
    return {
      viewData: {}
    }
  },
  mounted() {

  },
  created() {
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  },
  destroyed: function () {
  },
}
</script>

<style lang="less" scoped>

.update-box {
  width: 5.43rem;
  height: 7.2rem;
  background: url("~@/assets/img/update.gif") no-repeat center;
  background-size: 5.43rem;
  margin: 1rem auto;

  h1 {
    font-size: 0.6rem;
    font-family: ZHSCNMT-GBK;
    font-weight: 400;
    color: #0088FF;
    text-align: center;
    padding-top: 2rem;
  }

  p {
    font-size: 0.3rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
  }
}

</style>
